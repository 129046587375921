export const tourcat = [
  'adventure',
  'city',
  'cruises',
  'sea',
  'travel',
  'wedding',
]
export const blogcat = ['adventure', 'popular', 'travel', 'couple', 'family']
export const destcat = [
  'adventure',
  'city',
  'cruises',
  'sea',
  'travel',
  'wedding',
]
export const cars = [
  'Sedan',
  'Noah',
  'Alphard',
  'Mercedes-Benz',
  'Tour Van',
  'Tour Landcruisers',
  'Prado',
  'Coaster Bus',
]
export const destination = [
  'Nairobi',
  'Mombasa, ',
  'Nakuru,',
  'kisumu',
  'Maasai mara',
  'Amboseli',
  'Malindi',
  'Lamu',
  'Malaba',
]
