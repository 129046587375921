export const carsgallery = [
  {
    tourid: 1,
    photos: [
      '../../fleet/benz-s-class.jpg',
      '../../fleet/benz-s-class.jpg',
      '../../fleet/benz-s-class.jpg',
      '../../fleet/benz-s-class.jpg',
      '../../fleet/benz-s-class.jpg',
    ],
  },
  {
    tourid: 2,
    photos: [
      '../../fleet/interior-benz-5.jpg',
      '../../fleet/benz-s-class.jpg',
      '../../fleet/interior-benz-4.jpg',
      '../../fleet/benz-s-class.jpg',
      '../../fleet/interior-benz-2.jpg',
    ],
  },
  {
    tourid: 3,
    photos: [
      '../../fleet/prado_main.jpg',
      'prado1.jpg',
      'prado2.jpg',
      'pradointerior1.jpg',
      'pradointerior2.jpg',
      'pradointerior3.jpg',
    ],
  },
  {
    tourid: 4,
    photos: [
      '../../fleet/cruz1.png',
      '../../fleet/cruz1.png',
      '../../fleet/cruz1.png',
      '../../fleet/cruz1.png',
    ],
  },
  {
    tourid: 5,
    photos: [
      '../../fleet/premio2.jpg',
      'premio1.webp',
      'premio2.jpg',
      'premioint1.jpg',
      'premioint2.jpg',
      'premioint3.jpg',
      'premio3.jpg',
    ],
  },
  {
    tourid: 6,
    photos: [
      '../../fleet/alphard1.jpg',
      '../../fleet/alphard2.jpg',
      '../../fleet/alphard3.jpg',
      '../../fleet/alphard4.jpg',
      '../../fleet/alphardint1.jpg',
      '../../fleet/alphardint2.jpg',
    ],
  },
  {
    tourid: 7,
    photos: [
      '../../fleet/noah-pink.jpg',
      'noah-pink.jpg',
      'noah-white.jpg',
      'noah-pink.jpg',
    ],
  },
  {
    tourid: 8,
    photos: ['noah-pink.jpg', 'noah-white.jpg', 'noah-pink.jpg'],
  },
  {
    tourid: 9,
    photos: ['noah-pink.jpg', 'noah-white.jpg', 'noah-pink.jpg'],
  },
  {
    tourid: 10,
    photos: ['noah-pink.jpg', 'noah-white.jpg', 'noah-pink.jpg'],
  },
]
