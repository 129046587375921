export const destination = [
  {
    id: 1,
    name: 'Sedan / Saloon',
    category: 'Sedan , Saloon',
    title: 'Sedan / Saloon',
    description: ['One of the most famous Sedan / Saloon .'],
    note: 'Note: ',
    thumbnail: '../../fleet/premio2.jpg',
    images: [
      '../../fleet/premio2.jpg',
      '../../fleet/premio1.webp',
      '../../fleet/premio2.jpg',
      '../../fleet/premioint1.jpg',
      '../../fleet/premioint2.jpg',
      '../../fleet/premioint3.jpg',
      '../../fleet/premio3.jpg',
    ],
    details: ['Read the basic info.'],
  },
  {
    id: 2,
    name: 'Noah',
    category: 'Toyota Noah',
    title: 'Noah',
    description: ['One of the most famous .'],
    note: 'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
    thumbnail: '../../fleet/noah1.jpg',
    images: [
      '../../fleet/noah2.jpg',
      '../../fleet/noah3.jpg',
      '../../fleet/noah4.jpg',
      '../../fleet/noahint1.jpg',
      '../../fleet/noahint2.jpg',
    ],
    details: ['Read the basic info .'],
  },
  {
    id: 3,
    name: 'alphard',
    category: 'travel',
    title: 'Toyota Alphard',
    description: ['One of the most famous .'],
    note: 'Note: ',
    thumbnail: '../../fleet/alphard1.jpg',
    images: [
      '../../fleet/alphard1.jpg',
      '../../fleet/alphard2.jpg',
      '../../fleet/alphard3.jpg',
      '../../fleet/alphard4.jpg',
      '../../fleet/alphardint1.jpg',
      '../../fleet/alphardint2.jpg',
    ],
    details: ['Read the basic info .'],
  },
  {
    id: 4,
    name: 'Landcruiser',
    category: 'cruises',
    title: 'Land cruizer toyota',
    description: ['One of the most famous .'],
    note: 'Note: .',
    thumbnail: '../../fleet/cruz1.png',
    images: [
      '../../fleet/cruz1.png',
      '../../fleet/cruz1.png',
      '../../fleet/cruz1.png',
    ],
    details: ['Read the basic info '],
  },
  {
    id: 5,
    name: 'mercedes Benz',
    title: 'mercedes Benz',
    category: 'mercedes Benz',
    description: ['mercedes Benz'],
    note: 'mercedes Benz',
    thumbnail: '../../fleet/benz-s-class.jpg',
    images: [
      '../../fleet/benz-s-class.jpg',
      '../../fleet/benz-s-class.jpg',
      '../../fleet/benz-s-class.jpg',
      '../../fleet/benz-s-class.jpg',
      '../../fleet/benz-s-class.jpg',
    ],
    details: ['Read more.....'],
  },
  {
    id: 6,
    name: 'Prado',
    category: 'toyota',
    title: 'toyota prado',
    description: ['One of the most famous '],
    note: 'latest prado.',
    thumbnail: '../../fleet/prado_main.jpg',
    images: [
      '../../fleet/prado_main.jpg',
      '../../fleet/prado1.jpg',
      '../../fleet/prado2.jpg',
      '../../fleet/pradointerior1.jpg',
      '../../fleet/pradointerior2.jpg',
      '../../fleet/pradointerior3.jpg',
    ],
    details: ['Read the basic info .'],
  },
]
