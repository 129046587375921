import React, { useEffect, useRef } from 'react'
import '../sass/footer.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { cars } from '../data/category'
import { Link } from 'react-router-dom'

const Footer = () => {
  const textRef = useRef()

  useEffect(() => {
    let text = textRef.current.innerText
    text = text.split('')
    textRef.current.innerHTML = text
      .map(
        (char, index) =>
          `<h6 style="transform:rotate(${index * 3.7}deg)">${char}</h6>`
      )
      .join('')
  }, [])

  const capitalizedcat = cars.map(
    (cat) => cat.charAt(0).toUpperCase() + cat.slice(1)
  )

  return (
    <section className='footer__sec pt-5'>
      <Container className='pt-5 pb-4'>
        <Row className='mb-5'>
          <Col
            lg={3}
            sm={6}
            className='circle__con mb-5 mb-lg-0 justify-content-start justify-content-lg-center'
          >
            <div className='footer__circle'>
              <img
                src='../../assets/images/jtl-logo.jpg'
                alt='jetra'
                style={{ borderRadius: '50%' }}
              />
              <p>Since: 2023</p>
              <div className='text' ref={textRef}>
                Jetra.logistics.travel.hub.Jetra.logistics.travel.hub.jetralogisctics.com.
                Jetra.logistics.travel
              </div>
            </div>
          </Col>
          <Col
            lg={3}
            sm={6}
            className='footer__info__con mb-5 mb-lg-0 justify-content-start justify-content-lg-center'
          >
            <div>
              <p className='footer__title'>Our services</p>
              <ul>
                <li>
                  <p>FLIGHT BOOKINGS</p>
                </li>
                <li>
                  <p>LUXURY TRANSFERS</p>
                </li>
                <li>
                  <p>AIRPORT MEET & GREET</p>
                </li>
                <li>
                  <p>GROUND SERVICESt</p>
                </li>
                <li>
                  <p>VISA ASSISTANCE</p>
                </li>
              </ul>
            </div>
          </Col>
          <Col
            lg={3}
            sm={6}
            className='footer__info__con mb-5 mb-lg-0 justify-content-start justify-content-lg-center'
          >
            <div>
              <p className='footer__title'>Our cars</p>
              <ul>
                {capitalizedcat.map((item, index) => (
                  <li key={index}>
                    <p>{item}</p>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col
            lg={3}
            sm={6}
            className='footer__info__con mb-5 mb-lg-0 justify-content-start justify-content-lg-center'
          >
            <div>
              <p className='footer__title'>Reach Us</p>
              <ul>
                <li className='d-flex gap-3 align-items-center'>
                  <i className='bi bi-telephone'></i>
                  <div className='d-flex flex-column gap-2'>
                    <p>+254 716 643 157</p>
                    <p>+254 716 643 193</p>
                  </div>
                </li>
                <li className='d-flex gap-3 align-items-center'>
                  <i className='bi bi-envelope'></i>
                  <div className='d-flex flex-column gap-2'>
                    <p>info@jetralogistics.com</p>
                  </div>
                </li>
                <li className='d-flex gap-3 align-items-center'>
                  <i className='bi bi-geo-alt'></i>
                  <div className='d-flex flex-column gap-2'>
                    <p>
                      Nairobi, Jomo Kenyatta International Airport Terminal 1A
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className='py-4 mb-5'>
          <Col
            lg={6}
            className='col-12 footer__middle d-flex gap-4 align-items-center mb-5 mb-xl-0'
          >
            <p>Stay Connected:</p>
            <ul className='d-flex align-items-center gap-4 flex-wrap'>
              <li>
                <Link
                  to={`https://www.facebook.com/profile.php?id=100092706566942`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <p>
                    <i className='bi bi-facebook'></i>
                  </p>
                </Link>
              </li>
              <li>
                <p>
                  <i className='bi bi-twitter'></i>
                </p>
              </li>
              <li>
                <Link
                  to={`https://www.instagram.com/jetratravels/?igshid=MzNlNGNkZWQ4Mg%3D%3D`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <p>
                    <i className='bi bi-instagram'></i>
                  </p>
                </Link>
              </li>
              <li>
                <p>
                  <i className='bi bi-pinterest'></i>
                </p>
              </li>
            </ul>
          </Col>
          <Col
            lg={6}
            className='col-12 footer__middle d-flex gap-4 align-items-center justify-content-start justify-content-lg-end'
          >
            <p>Pay Safely With Us!</p>
            <ul className='d-flex align-items-center gap-4 flex-wrap'>
              <li>
                <img
                  src='https://astrip-wp.b-cdn.net/wp-content/themes/astrip/includes/theme-options/images/footer/pay1.svg  '
                  alt='jetra travels'
                />
              </li>
              <li>
                <img
                  src='https://astrip-wp.b-cdn.net/wp-content/themes/astrip/includes/theme-options/images/footer/pay2.svg'
                  alt='jetra travels'
                />
              </li>
              <li>
                <img
                  src='https://astrip-wp.b-cdn.net/wp-content/themes/astrip/includes/theme-options/images/footer/pay3.svg'
                  alt='jetra travels'
                />
              </li>
              <li>
                <img
                  src='https://astrip-wp.b-cdn.net/wp-content/themes/astrip/includes/theme-options/images/footer/pay4.svg'
                  alt='jetra travels'
                />
              </li>
            </ul>
          </Col>
        </Row>
        <Row className=' footer__low__con pt-4'>
          <Col lg={6} className='footer__lower mb-2 mb-lg-0'>
            <p>
              Copyright 2023 <span>Jetra Logistics</span> | Design By{' '}
              <span>
                {' '}
                <a
                  href='https://brian-nyachae.netlify.app/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Brian
                </a>
              </span>
            </p>
          </Col>
          <Col
            lg={6}
            className='footer__lower d-flex justify-content-lg-end justify-content-start'
          >
            <ul className='d-flex gap-2'>
              <li>
                <p>Privacy Policy</p>
              </li>
              <li> | </li>
              <li>
                <p>Terms of Use</p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Footer
