import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Parralax = () => {
  return (
    <div className='parallax'>
      <div className='overlay'>
        <Container className='text__con'>
          <div className='text '>
            <p>Feel the vibe</p>
            <h4>Or anywhere you need go!..</h4>
            <p>
              Be it international or local, from the busy city buzzing streets,
              to the amazing wild Exploreration and relaxing Beach experience.
            </p>
            <LinkContainer to={'/tour'}>
              <Button className='find__now '>
                <div>
                  <span className='transition' />
                  <span className='label'>Find Now</span>
                </div>
              </Button>
            </LinkContainer>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Parralax
