export const gallery = [
  {
    tourid: 1,
    photos: [
      '../destinations/masai-mara-main.jpg',
      '../destinations/giraffe-masai-mara.jpg',
      '../destinations/lion-masai-mara.jpg',
    ],
  },
  {
    tourid: 2,
    photos: [
      '../destinations/masai-mara-main.jpg',
      '../destinations/giraffe-masai-mara.jpg',
      '../destinations/lion-masai-mara.jpg',
    ],
  },
  {
    tourid: 3,
    photos: [
      '../destinations/crocodile.jpg',
      '../destinations/giraffe-masai-mara.jpg',
      '../destinations/buffalo-masai-mara.jpg',
    ],
  },
  {
    tourid: 4,
    photos: [
      '../destinations/bomas-2.jpg',
      '../destinations/bomas-3.jpg',
      '../destinations/bomas-4.jpg',
      '../destinations/bomas-1.jpg',
    ],
  },
  {
    tourid: 6,
    photos: [
      '../destinations/girraffe-centre1.jpg',
      '../destinations/girraffe-centre2.jpg',
      '../destinations/girraffe-centre3.jpg',
      '../destinations/girraffe-centre4.jpg',
      '../destinations/girraffe-centre5.jpg',
    ],
  },

  {
    tourid: 7,
    photos: [
      '../destinations/lake-nakuru-1.jpg',
      '../destinations/lake-nakuru-2.jpg',
      '../destinations/lake-nakuru-3.jpg',
      '../destinations/lake-nakuru-4.jpg',
      '../destinations/lake-nakuru.jpg',
    ],
  },
  {
    tourid: 8,
    photos: [
      '../destinations/nairobi-city-2.jpg',
      '../destinations/nairobi-city-3.jpg',
      '../destinations/nairobi-city-1.jpg',
      '../destinations/nairobi-city-4.jpg',
    ],
  },
]
