export const fleet = [
  {
    id: 1,
    name: 'Sedan / Saloon',
    model: 'manual / Automatic',
    category: ['sedan', ' saloon '],
    price: 20,
    discount: 16,
    children: 25,
    duration: {
      days: 0,
      night: 1,
    },
    month: {
      checkin: 'February 1, 2023',
      checkout: 'February 8,2023',
    },
    max: 6,
    description: [
      'Toyota Motor Corporation premio Diesel Engine Type - 6 Cylinder Fuel Type-Diesel Fuel Tank Capacity Ltrs-130L Transmission - 5 Speed Manual (Accessories) -Good music -Wi-fi router -Recliner seat -Charging sockets -Pop up rooftop for clear view Max Capacity 8 Pass, Inclusive driver allowance. Suitable for safari and rough roads in Kenya Comprehensive insurance All inclusive.',
    ],
    thumbnail: '../../fleet/premio4.png',
    images: [
      '../../fleet/premio2.jpg',
      'premio1.webp',
      'premio2.jpg',
      'premioint1.jpg',
      'premioint2.jpg',
      'premioint3.jpg',
      'premio3.jpg',
    ],
    FromJkia: '20 USD',
    FulldayWithinNairobi: 'USD 80',
    Rateincludes: 'Rate includes Vehicle hire with Driver and Fuel',
    FourTosixHours: 'USD 50',
    included: [
      'Free Best Tour Guided',
      'drinking water',
      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: ['Additional Services', 'Insurance', 'Any Private Expenses'],
  },
  {
    id: 2,
    name: 'Noah',
    model: 'Toyota Noah ',
    category: ['Noah', 'Toyota Noah '],
    price: 30,
    discount: 16,
    children: 40,
    duration: {
      days: 8,
      night: 9,
    },
    month: {
      checkin: 'February 1, 2023',
      checkout: 'February 8,2023',
    },
    max: 5,
    description: [
      'Toyota Noah (H30W) with moderate customs in 1/18 scale. Body colour: Black, LD97 LD-12 type 20inch wheel (Polish & Black), Lowering, Flap type spoiler (Front, Side, Rear), Interiors in Black, Dual exhaust pipes.',
    ],
    thumbnail: '../../fleet/noah1.jpg',
    images: [
      '../../fleet/noah2.jpg',
      '../../fleet/noah3.jpg',
      '../../fleet/noah4.jpg',
      '../../fleet/noahint1.jpg',
      '../../fleet/noahint2.jpg',
    ],
    FromJkia: 'USD 30',
    FulldayWithinNairobi: 'FULL DAY not specifically Nairobi 130 USD',
    Rateincludes: 'Rate includes Vehicle hire with Driver and Fuel',
    FourTosixHours: '80 USD',
    included: [
      'Free Best Tour Guided',
      'drinking water',
      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: ['Additional Services', 'Insurance', 'Any Private Expenses'],
  },
  {
    id: 3,
    name: 'Toyota Alphard',
    model: 'Automatic',
    category: ['Toyota alphard ', 'white'],
    price: 40,
    discount: 16,
    children: 100,
    duration: {
      days: 8,
      night: 9,
    },
    month: {
      checkin: 'February 1, 2023',
      checkout: 'February 8,2023',
    },
    max: 5,
    description: [
      'Alphard (H30W) with moderate customs in 1/18 scale. Body colour: Black, LD97 LD-12 type 20inch wheel (Polish & Black), Lowering, Flap type spoiler (Front, Side, Rear), Interiors in Black, Dual exhaust pipes.',
    ],
    thumbnail: '../../fleet/alphard1.jpg',
    images: [
      '../../fleet/alphard1.jpg',
      '../../fleet/alphard2.jpg',
      '../../fleet/alphard3.jpg',
      '../../fleet/alphard4.jpg',
      '../../fleet/alphardint1.jpg',
      '../../fleet/alphardint2.jpg',
    ],
    FromJkia: 'USD 40',
    FulldayWithinNairobi: 'USD 150',
    Rateincludes: 'Rate includes Vehicle hire with Driver and Fuel',
    FourTosixHours: 'USD 80',
    included: [
      'Free Best Tour Guided',
      'drinking water',
      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: ['Additional Services', 'Insurance', 'Any Private Expenses'],
  },
  {
    id: 4,
    name: 'Landcruiser Tour van',
    model: 'Landcruiser Tour van manual / Automatic',
    category: ['manual', 'offroad ', 'land cruizer'],
    price: 40,
    discount: 16,
    children: 125,
    duration: {
      days: 1,
      night: 1,
    },
    month: {
      checkin: 'February 1, 2023',
      checkout: 'February 8,2023',
    },
    max: 8,
    description: [
      'Landcruiser Tour van 4x4 Engine- 4.2l Diesel Engine Type - 6 Cylinder Fuel Type-Diesel Fuel Tank Capacity Ltrs-130L Transmission - 5 Speed Manual (Accessories) -Good music -Wi-fi router -Recliner seat -Charging sockets -Pop up rooftop for clear view Max Capacity 8 Pass, Inclusive driver allowance. Suitable for safari and rough roads in Kenya Comprehensive insurance All inclusive.',
    ],
    thumbnail: '../../fleet/cruz1.png',
    images: [
      '../../fleet/cruz1.png',
      '../../fleet/cruz1.png',
      '../../fleet/cruz1.png',
    ],
    FromJkia: '100',
    FulldayWithinNairobi: '250 USD OUTSIDE NAIROBI QUOTE ON REQUEST',
    Rateincludes: 'Rate includes Vehicle hire with Driver and Fuel',
    FourTosixHours: 'USD 150',
    included: [
      'Free Best Tour Guided',
      'drinking water',
      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: [
      'Park entrance fee',
      'Fuel',
      'Additional Services',
      'Insurance',
      'Any Private Expenses',
    ],
  },
  {
    id: 5,
    name: 'mercedes Benz',
    model: 'mercedes Benz',
    category: ['mercedez ', 'Benz'],
    price: 120,
    discount: 16,
    children: 100,
    duration: {
      days: 8,
      night: 9,
    },
    month: {
      checkin: 'February 1, 2023',
      checkout: 'February 8,2023',
    },
    max: 5,
    description: [
      'The Mercedes Benz E Class chauffeur car is the perfect executive level chauffeur car for all types of business trips and airport transfers. The E Class packs safety, luxury and style into one very affordable package. The executive E Class has always been popular and like it is big brother the S Class Limousine features many of the hallmarks that have made Mercedes-Benz one of the finest automobile manufacturers.',
    ],
    thumbnail: '../../fleet/benz-s-class.jpg',
    images: [
      'interior-benz-5.jpg',
      'interior-benz-4.jpg',
      'interior-benz-2.jpg',
    ],
    FromJkia: 'USD 120',
    FulldayWithinNairobi: 'USD 300',
    Rateincludes: 'Vehicle hire with Driver and Fuel',
    FourTosixHours: '',
    included: [
      'Free Best Tour Guided',
      'drinking water',
      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: ['Additional Services', 'Insurance', 'Any Private Expenses'],
  },
  {
    id: 6,
    name: 'Prado',
    model: 'toyota',
    category: ['cruises', 'mercedes ', 'Benz'],
    price: 100,
    discount: 16,
    children: 100,
    duration: {
      days: 8,
      night: 9,
    },
    month: {
      checkin: 'February 1, 2023',
      checkout: 'February 8,2023',
    },
    max: 5,
    description: [
      'Toyota prado with moderate customs in 1/18 scale. Body colour: Black, LD97 LD-12 type 20inch wheel (Polish & Black), Lowering, Flap type spoiler (Front, Side, Rear), Interiors in Black, Dual exhaust pipes.',
    ],
    thumbnail: '../../fleet/prado_main.jpg',
    images: [
      '../../fleet/prado_main.jpg',
      'prado1.jpg',
      'prado2.jpg',
      'pradointerior1.jpg',
      'pradointerior2.jpg',
      'pradointerior3.jpg',
    ],
    FromJkia: 'USD 100',
    FulldayWithinNairobi: 'USD 250',
    Rateincludes: 'Rate includes Vehicle hire with Driver and Fuel',
    FourTosixHours: 'contact us for this inquiry',
    included: [
      'Free Best Tour Guided',
      'drinking water',
      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: ['Additional Services', 'Insurance', 'Any Private Expenses'],
  },

  {
    id: 7,
    name: ' Coaster Bus',
    model: ' Coaster Bus manual / Automatic',
    category: ['bus', ' Coaster Bus '],
    price: 120,
    discount: 16,
    children: 40,
    duration: {
      days: 8,
      night: 9,
    },
    month: {
      checkin: 'February 1, 2023',
      checkout: 'February 8,2023',
    },
    max: '25',
    description: [
      'Coaster Bus with moderate customs in 1/18 scale. Body colour: Black, LD97 LD-12 type 20inch wheel (Polish & Black), Lowering, Flap type spoiler (Front, Side, Rear), Interiors in Black, Dual exhaust pipes.',
    ],
    thumbnail: '../../fleet/coster-bus-3.jpeg',
    images: ['coster-bus-1.png', 'coster-bus-2.jpg', 'coster-bus-4.png'],
    FromJkia: 'USD 120',
    FulldayWithinNairobi: 'USD 250',
    Rateincludes: 'Rate includes Vehicle hire with Driver and Fuel',
    FourTosixHours: '',
    included: [
      'Free Best Tour Guided',
      'drinking water',
      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: ['Additional Services', 'Insurance', 'Any Private Expenses'],
  },
]
