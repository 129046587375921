import React from 'react'
import '../sass/topheader.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'

const TopHeader = () => {
  return (
    <>
      <Helmet>
        <title>Jetra Travels</title>

        <meta
          name='description'
          data-rh='true'
          content="Looking for reliable and affordable Nairobi airport taxi services at JKIA? Book your comfortable and convenient airport transfer with us. We provide prompt pickups, experienced drivers, and competitive rates. Our 24/7 service ensures a smooth and hassle-free journey to your destination, whether you're traveling for business or leisure. Enjoy a safe and comfortable ride in our well-maintained fleet of taxis. With extensive knowledge of Nairobi and its surroundings, our drivers will ensure you reach your destination efficiently. Experience our professional and customer-focused service that prioritizes punctuality and customer satisfaction. Don't stress about transportation at JKIA—reserve your airport taxi service with us today and start your trip on the right foot. Contact us now for reliable Nairobi airport transfers."
        />

        <link
          rel='alternate'
          href='https://www.jetralogistics.com/'
          hreflang='en-eg'
        />
        <meta property='og:image' content='../../public/favicon.jpg' />
        <meta property='og:url' content='https://www.jetralogistics.com/' />
        <meta property='og:site_name' content='Jetra Travels' />
        <link rel='canonical' href='/' />
      </Helmet>
      <section className='top__header__sec d-none d-xl-block'>
        <Container>
          <Row className='align-items-center'>
            <Col xl={10} className='d-flex gap-5  align-items-center'>
              <div className='d-flex gap-2  align-items-center'>
                <i className='bi bi-stopwatch'></i>
                <p>availability 24/7</p>
              </div>
              <div className='d-flex gap-2  align-items-center'>
                <i className='bi bi-geo-alt'></i>
                <p>Jomo Kenyatta International Airport Terminal 1A, Nairobi</p>
              </div>
            </Col>
            <Col xl={2} className='d-flex justify-content-end'>
              <a>My Account</a>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default TopHeader
