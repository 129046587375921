import React, { useEffect, useState } from 'react'
import PageBanner from '../../components/PageBanner'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import '../../sass/singleTour/singletour.scss'
import { useParams } from 'react-router-dom'
import { fleet } from '../../data/fleet'
import { fleetplan } from '../../data/fleetplan'
import { carsgallery } from '../../data/carsgallery'
import FleetInfo from '../../components/singlefleet/FleetInfo'
// import TourPlan from '../../components/singletour/TourPlan'

import FleetBooking from '../../components/singlefleet/FleetBooking'
import FleetGallery from '../../components/singlefleet/FleetGallery'

const SingleFleet = () => {
  const { id } = useParams()

  const tourdetails = fleet.find((item) => {
    return item.id == id
  })

  const tourPlan = fleetplan.find((item) => {
    return item.tourid == id
  })

  const images = carsgallery.find((item) => {
    return item.tourid == id
  })

  return (
    <section>
      <PageBanner title={tourdetails.name} />
      <section className='single__page py-5'>
        <Container>
          <Row>
            <Col lg={8}>
              <section className='single__bio'>
                <Tabs
                  defaultActiveKey='home'
                  id='justify-tab-example'
                  className='mb-3'
                  justify
                >
                  <Tab eventKey='home' title='Information'>
                    <FleetInfo tourdetails={tourdetails} />
                  </Tab>
                  {/* <Tab eventKey='profile' title='pricing'>
                    <TourPlan plan={tourPlan} />
                  </Tab> */}
                  <Tab eventKey='longer-tab' title='fleet Gallery'>
                    <FleetGallery gallery={images} />
                  </Tab>
                </Tabs>
              </section>
            </Col>
            <Col lg={4}>
              <FleetBooking tourdetails={tourdetails} />
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  )
}

export default SingleFleet
