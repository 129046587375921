import React, { useState } from 'react'
import '../sass/home.scss'
import { Col, Container, Row } from 'react-bootstrap'
import Hero from '../components/Hero'
import Filter from '../components/Filter'
import Services from '../components/Services'
import DealHome from '../components/DealHome'
import Heading from '../components/Heading'
import DestinationHome from '../components/DestinationHome'
import FqaForm from '../components/FqaForm'
import Parralax from '../components/Parralax'
import TourSlide from '../components/TourSlide'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'
import BlogHome from '../components/BlogHome'
// import Partners from '../components/Partners'
import FleetHome from '../components/FleetHome'
import { Helmet } from 'react-helmet-async'

const Home = () => {
  const [countStart, setCountStart] = useState(false)
  return (
    <>
      <Helmet>
        <title>Jetra Travels</title>

        <meta
          name='description'
          data-rh='true'
          content="Looking for reliable and affordable Nairobi airport taxi services at JKIA? Book your comfortable and convenient airport transfer with us. We provide prompt pickups, experienced drivers, and competitive rates. Our 24/7 service ensures a smooth and hassle-free journey to your destination, whether you're traveling for business or leisure. Enjoy a safe and comfortable ride in our well-maintained fleet of taxis. With extensive knowledge of Nairobi and its surroundings, our drivers will ensure you reach your destination efficiently. Experience our professional and customer-focused service that prioritizes punctuality and customer satisfaction. Don't stress about transportation at JKIA—reserve your airport taxi service with us today and start your trip on the right foot. Contact us now for reliable Nairobi airport transfers."
        />

        <link
          rel='alternate'
          href='https://www.jetralogistics.com/'
          hreflang='en-eg'
        />
        <meta property='og:image' content='../../public/favicon.jpg' />
        <meta property='og:url' content='https://www.jetralogistics.com/' />
        <meta property='og:site_name' content='Jetra Travels' />
        <link rel='canonical' href='/' />
      </Helmet>
      <section className='home__sec'>
        <Hero />
        <Filter />
        <Heading title='SERVICES' subtitle='Our Main Services' />
        <Services />
        <Heading title='Fleet' subtitle='Amazing Fleet Deals!' />
        {/* <DealHome /> */}
        <FleetHome />
        <Heading title='Fleet' subtitle='our cars' />
        <DestinationHome />
        <Parralax />
        <FqaForm />

        <Heading title='CHOOSE YOUR TOUR' subtitle='Get The Best Plans' />
        <TourSlide />
        <section className='counter__sec'>
          <Container>
            <ScrollTrigger
              onEnter={() => setCountStart(true)}
              onExit={() => setCountStart(false)}
            >
              <Row className='counter__con'>
                <Col
                  lg={3}
                  md={6}
                  className='d-flex flex-column justify-content-center align-items-center'
                >
                  <i className='bi bi-globe-asia-australia'></i>
                  <h2>
                    {countStart && (
                      <CountUp end={400} duration={5} suffix='+' />
                    )}
                  </h2>
                  <p>Awesome Tour</p>
                </Col>
                <Col
                  lg={3}
                  md={6}
                  className='d-flex flex-column justify-content-center align-items-center'
                >
                  <i className='bi bi-geo-alt-fill'></i>
                  <h2>
                    {countStart && (
                      <CountUp end={250} duration={5} suffix='+' />
                    )}
                  </h2>
                  <p>Stunning Places</p>
                </Col>
                <Col
                  lg={3}
                  md={6}
                  className='d-flex flex-column justify-content-center align-items-center'
                >
                  <i className='bi bi-award'></i>
                  <h2>
                    {countStart && (
                      <CountUp end={650} duration={5} suffix='+' />
                    )}
                  </h2>
                  <p>Satisfied Customer</p>
                </Col>
                <Col
                  lg={3}
                  md={6}
                  className='d-flex flex-column justify-content-center align-items-center'
                >
                  <i className='bi bi-people-fill'></i>
                  <h2>
                    {countStart && (
                      <CountUp end={500} duration={5} suffix='+' />
                    )}
                  </h2>
                  <p>Travel Guides</p>
                </Col>
              </Row>
            </ScrollTrigger>
          </Container>
        </section>
        <Heading title='Services & Packages' subtitle='Checkout Our Services' />
        <BlogHome />
        {/* <Heading title='ASTRIP PARTNERS' subtitle='Our Most Trusted Partners' />
      <Partners /> */}
      </section>
    </>
  )
}

export default Home
