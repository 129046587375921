import React, { useState } from 'react'
import '../../sass/about/about.scss'
import PageBanner from '../../components/PageBanner'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Heading from '../../components/Heading'
import ScrollTrigger from 'react-scroll-trigger'
import CountUp from 'react-countup'
import { Helmet } from 'react-helmet-async'

const About = () => {
  const [countStart, setCountStart] = useState(false)

  const guides = [
    {
      image: '../tour-guides/tour-guide.jpg',
      name: 'jetra tour staff',
      title: 'Tour Guide',
    },
    {
      image: '../tour-guides/tour-gide-2.jpg',
      name: 'jetra tour staff',
      title: 'Tour Guide',
    },
    {
      image: '../tour-guides/tour-guide-3.jpg',
      name: 'jetra tour staff',
      title: 'Tour Guide',
    },
  ]
  return (
    <>
      <Helmet>
        <title>About</title>

        <meta
          name='description'
          data-rh='true'
          content="Looking for reliable and affordable Nairobi airport taxi services at JKIA? Book your comfortable and convenient airport transfer with us. We provide prompt pickups, experienced drivers, and competitive rates. Our 24/7 service ensures a smooth and hassle-free journey to your destination, whether you're traveling for business or leisure. Enjoy a safe and comfortable ride in our well-maintained fleet of taxis. With extensive knowledge of Nairobi and its surroundings, our drivers will ensure you reach your destination efficiently. Experience our professional and customer-focused service that prioritizes punctuality and customer satisfaction. Don't stress about transportation at JKIA—reserve your airport taxi service with us today and start your trip on the right foot. Contact us now for reliable Nairobi airport transfers."
        />

        <link
          rel='alternate'
          href='https://www.jetralogistics.com/'
          hreflang='en-eg'
        />
        <meta property='og:image' content='../../public/favicon.jpg' />
        <meta property='og:url' content='https://www.jetralogistics.com/' />
        <meta property='og:site_name' content='Jetra Travels' />
        <link rel='canonical' href='/about' />
      </Helmet>
      <section className='about__page'>
        <PageBanner title='About Us' />
        <section className='py-5 my-5'>
          <Container>
            <Row>
              <Col lg={6} className='text d-flex flex-column gap-4'>
                <p>
                  Located at the kenya's main airport (Jomo Kenyatta
                  international Airport Terminal 1A ).Jetra logistics Limited
                  offers luxury Executive airport transfers. Our bispoke
                  services combined with meticulous attention to detail and
                  personalize approach, creates the ultimate luxury chauffeur
                  experience.
                </p>
                <p>
                  We aim not only to meet but exceed your expectations and build
                  long term professional relationships with our clients. Our
                  chauffeurs work around the clock to ensure your journey is a
                  pleasant and hussle-free as possible.
                </p>

                <Button className='find__now mt-4'>
                  <div>
                    <span className='transition' />
                    <span className='label'>Find Tour</span>
                  </div>
                </Button>
              </Col>
              <Col lg={6} className='d-none d-lg-block'>
                <Row>
                  <Col lg={6} className='img__con1'>
                    <div className='one'>
                      <img
                        className='w-100 '
                        src='https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/feature3.png'
                        alt='jetra travels'
                      />
                    </div>
                  </Col>
                  <Col lg={6} className='img__con2'>
                    <div className='two'>
                      <img
                        className='w-100'
                        src='../destinations/one-giraffe-masai-mara.jpg'
                        alt='jetra travels'
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <Heading title="TOUR GUIDE'S" subtitle="Meet Our Excellent Guide's" />
        <Container className='guides mb-5'>
          <div className=' d-flex gap-4 '>
            {guides.map((item, index) => (
              <div key={index} className='card__item'>
                <div className='card__con'>
                  <img className='w-100' src={item.image} alt='jetra tavels' />
                </div>
                <div className='text-center py-4'>
                  <h4>{item.name}</h4>
                  <p>{item.title}</p>
                </div>
              </div>
            ))}
          </div>
        </Container>
        <section className='counter__sec mb-5'>
          <Container>
            <ScrollTrigger
              onEnter={() => setCountStart(true)}
              onExit={() => setCountStart(false)}
            >
              <Row className='counter__con'>
                <Col
                  lg={3}
                  md={6}
                  className='d-flex flex-column justify-content-center align-items-center'
                >
                  <i className='bi bi-globe-asia-australia'></i>
                  <h2>
                    {countStart && (
                      <CountUp end={400} duration={5} suffix='+' />
                    )}
                  </h2>
                  <p>Awesome Tour</p>
                </Col>
                <Col
                  lg={3}
                  md={6}
                  className='d-flex flex-column justify-content-center align-items-center'
                >
                  <i className='bi bi-geo-alt-fill'></i>
                  <h2>
                    {countStart && (
                      <CountUp end={250} duration={5} suffix='+' />
                    )}
                  </h2>
                  <p>Stunning Places</p>
                </Col>
                <Col
                  lg={3}
                  md={6}
                  className='d-flex flex-column justify-content-center align-items-center'
                >
                  <i className='bi bi-award'></i>
                  <h2>
                    {countStart && (
                      <CountUp end={650} duration={5} suffix='+' />
                    )}
                  </h2>
                  <p>Satisfied Customer</p>
                </Col>
                <Col
                  lg={3}
                  md={6}
                  className='d-flex flex-column justify-content-center align-items-center'
                >
                  <i className='bi bi-people-fill'></i>
                  <h2>
                    {countStart && (
                      <CountUp end={500} duration={5} suffix='+' />
                    )}
                  </h2>
                  <p>Travel Guides</p>
                </Col>
              </Row>
            </ScrollTrigger>
          </Container>
        </section>
      </section>
    </>
  )
}

export default About
