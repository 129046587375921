export const tourplan = [
  {
    tourid: 1,
    days: [
      {
        day: 1,
        role: 'Departure',
        description: 'NAIROBI - MAASAI MARA DAY I',
        activities: [
          'Admire MAASAI MARA ',
          'Chance to spot prominent landmarks ',
        ],
      },
      {
        day: 2,
        role: 'MAASAI MARA',
        description:
          'Arrive At Masai Mara, welcome greeting by our representative who will assist you and provide tour guides to  in beutiful scenaris.',
        activities: [
          'Game Drives: Embark on thrilling game drives across the Maasai Mara National Reserve.',
          'Hot Air Balloon Safaris',
          'Maasai Village Visits',
          'Guided Nature Walks',
          'Photography Safaris',
          'Bush Dinners and Sundowners',
          'Cultural Performances',
        ],
      },
      {
        day: 3,
        role: 'MAASAI MARA - NAIROBI',
        description:
          'On Day 03 of your itinerary, you will be leaving the Maasai Mara and heading back to Nairobi. This day typically involves a return journey from the Maasai Mara National Reserve to the bustling capital city of Kenya.After enjoying your final morning game drive in the Maasai Mara, where you may have one last chance to spot some amazing wildlife, you will start your journey back to Nairobi. The drive from the Maasai Mara to Nairobi usually takes several hours, depending on the road conditions and any stops along the way.',
        activities: null,
      },
    ],
  },
  {
    tourid: 2,
    days: [
      {
        day: 1,
        role: 'Departure',
        description: 'NAIROBI - AMBOSELI DAY I',
        activities: ['Arrival at pickup location', 'Tour begins'],
      },
      {
        day: 2,
        role: 'AMBOSELI',
        description:
          'Arrive At AMBOSELI, welcome greeting by our representative who will assist you and provide tour guides ',
        activities: [
          'Game Drives: Embark on thrilling game drives across the Maasai Mara National Reserve.',
          'Hot Air Balloon Safaris',
          'Maasai Village Visits',
          'Guided Nature Walks',
          'Photography Safaris',
          'Bush Dinners and Sundowners',
          'Cultural Performances',
        ],
      },
      {
        day: 3,
        role: 'AMBOSELI - NAIROBI',
        description:
          'On Day 03 of your itinerary, you will be leaving the Maasai Mara and heading back to Nairobi. This day typically involves a return journey from the Maasai Mara National Reserve to the bustling capital city of Kenya.After enjoying your final morning game drive in the Maasai Mara, where you may have one last chance to spot some amazing wildlife, you will start your journey back to Nairobi. The drive from the Maasai Mara to Nairobi usually takes several hours, depending on the road conditions and any stops along the way.',
        activities: null,
      },
    ],
  },
  {
    tourid: 3,
    days: [
      {
        day: 1,
        role: 'Nairobi National Park',
        description:
          'Arrive At Nairobi National Park, welcome greeting by our representative who will assist you and provide tour guides to  in beutiful scenaris.',
        activities: [
          'drives across the Nairobi National Park.',

          'Guided Nature Walks',
          'Photography Safaris',
          'Bush Dinners and Sundowners',
          'Cultural Performances',
        ],
      },
    ],
  },
  {
    tourid: 4,
    days: [
      {
        day: 1,
        role: 'Departure to Bomas of Kenya',
        description: 'Explore Bomas of Kenya',
        activities: [
          'Admire kenyan culture',
          'visit diffrent tribes',
          'welcome greeting by our representative',
        ],
      },
    ],
  },
  {
    tourid: 5,
    days: [
      {
        day: 1,
        role: 'Departure to Karen Blixen',
        description:
          'Arrive Karen Blixen Museum, welcome greeting by our representative who will assist you  whenever needed',
        activities: [
          'Admire Blixen Museum',
          'Chance to spot prominent landmarks of the city',
          'Admire Blixen Museum',
          'Chance to spot prominent landmarks of the city',
          'scenic drive',
        ],
      },
    ],
  },
  {
    tourid: 6,
    days: [
      {
        day: 1,
        role: 'Departure To Nairobi Giraffe Center',
        description: 'Arrive giraffe Center',
        activities: [
          'Admire Nairobi girrafe center',
          'Chance to spot diffrent Giraffe species',
          'Chance to spot prominent landmarks of the city',
          'scenic drive',
        ],
      },
    ],
  },
  {
    tourid: 7,
    days: [
      {
        day: 1,
        role: 'Lake Nakuru',
        description:
          ' get ready for an unforgettable adventure. Your journey begins as you leave Nairobi, Kenya bustling capital city, and embark on a scenic drive towards Lake Nakuru National Park.',
        activities: ['Admire beutiful scenaries', 'hiking', 'photography'],
      },
      {
        day: 2,
        role: 'Adventure Begins',
        description: 'Arrive at nakuru',
        activities: [
          'Photography',
          'Chance to spot prominent landmarks of the city',
          'scenic drive',
        ],
      },
    ],
  },
  {
    tourid: 8,
    days: [
      {
        day: 1,
        role: 'Nairobi city three hours tour ',
        description:
          'Arrive JKIA airport, welcome greeting by our representative who will assist you on your three hours tour within Nairobi City',
        activities: [
          'Admire Nairobi city',
          'Chance to spot prominent landmarks of the city',
          'Photography',
        ],
      },
    ],
  },
]
