import React, { useState } from 'react'
import { Button, Col, Row, Modal, Form } from 'react-bootstrap'
import { useForm, ValidationError } from '@formspree/react'
import Swal from 'sweetalert2'
import ThreeDots from '../loading_state/ThreeDots'

const Booking = ({ tourdetails }) => {
  const [showModal, setShowModal] = useState(false)
  const [state, handleSubmit] = useForm('mgebjwdj')
  if (state.succeeded) {
    Swal.fire(
      'welcome!',
      `you have successfully booked tour to ${tourdetails.name}`,
      'success'
    )
    return (
      <div
        style={{
          fontStyle: 'italic',
          backgroundColor: 'green',
          padding: '10px',
          color: 'white',
          borderRadius: '40px',
        }}
      >
        Thanks for choosing jetra travel hub
      </div>
    )
  }
  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <section className='booking__card py-5 px-4'>
      <div className='heading text-center mb-4'>
        <h3>Online Booking </h3>
      </div>
      <h4
        style={{
          fontStyle: 'italic',
          backgroundColor: '#cc3300',
          padding: '6px',
          color: 'white',
          borderRadius: '40px',
        }}
      >
        price from ${tourdetails.price}
      </h4>
      <h3>book {tourdetails.name}</h3>
      <div className='d-flex justify-content-center pt-4'>
        <Button className='find__now' onClick={handleShowModal}>
          <div>
            <span className='transition' />
            <span className='label'>Book Now</span>
          </div>
        </Button>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Book {tourdetails.name} Now </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>name</Form.Label>
              <ValidationError
                prefix='name'
                field='name'
                errors={state.errors}
              />
              <Form.Control type='text' name='name' />
            </Form.Group>
            <Form.Group>
              <Form.Label>email</Form.Label>
              <ValidationError
                prefix='Email'
                field='email'
                errors={state.errors}
              />
              <Form.Control htmlFor='email' type='email' name='email' />
            </Form.Group>
            <Form.Group>
              <Form.Label>phone number</Form.Label>
              <ValidationError
                prefix='contact'
                field='contact'
                errors={state.errors}
              />
              <Form.Control type='text' name='contact' id='contact' />
            </Form.Group>

            <Form.Group>
              <Form.Label>Number of People</Form.Label>
              <ValidationError
                prefix='number_of_people'
                field='number_of_people'
                errors={state.errors}
              />
              <Form.Control type='number' name='number_of_people' />
            </Form.Group>

            <Form.Group>
              <Form.Label>Date</Form.Label>
              <ValidationError
                prefix='date'
                field='date'
                errors={state.errors}
              />
              <Form.Control type='datetime-local' name='date' />
            </Form.Group>
            <Form.Group>
              <Form.Label>Destination</Form.Label>
              <ValidationError
                prefix='destinationl'
                field='destination'
                errors={state.errors}
              />
              <Form.Control
                type='text'
                placeholder='destination'
                name='destination'
                defaultValue={tourdetails.name}
              />
            </Form.Group>
            <br />
            <Button variant='primary' type='submit' disabled={state.submitting}>
              {state.submitting ? <ThreeDots /> : 'Submit'}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  )
}

export default Booking
