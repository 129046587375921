import React from 'react'
import '../sass/services.scss'
import { Col, Container, Row } from 'react-bootstrap'
import Heading from './Heading'
import { Link, NavLink } from 'react-router-dom'

const Services = () => {
  return (
    <section className='popular__tour'>
      <Container>
        <div className='content'>
          <Row className=''>
            <Col xl={2} md={6} className='col__con'>
              <div className='inner__con'>
                <div className='svg__con'>
                  <img
                    src='/icons/taxi.png'
                    alt='jetra travels'
                    style={{ height: '70px' }}
                  />
                </div>
                <NavLink to='/blogs/1' exact>
                  <p style={{ color: '#cc3300' }}>TAXI</p>
                </NavLink>
              </div>
            </Col>
            <Col xl={2} md={6} className='col__con'>
              <div className='inner__con'>
                <div className='svg__con'>
                  <img
                    src='/icons/transfer.png'
                    alt='jetra travels'
                    style={{ height: '70px' }}
                  />
                </div>
                <NavLink to='/blogs/2' exact>
                  <p style={{ color: '#cc3300' }}>
                    AIRTPORT <br />
                    TRANSFERS
                  </p>
                </NavLink>
              </div>
            </Col>
            <Col xl={2} md={6} className='col__con'>
              <div className='inner__con'>
                <div className='svg__con'>
                  <img
                    src='/icons/meeting.png'
                    alt='jetra travels'
                    style={{ height: '70px' }}
                  />
                </div>
                <NavLink to='/blogs/3' exact>
                  <p style={{ color: '#cc3300' }}>
                    Meet & <br /> Greet
                  </p>
                </NavLink>
              </div>
            </Col>
            <Col xl={2} md={6} className='col__con'>
              <div className='inner__con'>
                <div className='svg__con'>
                  <img
                    src='/icons/service-assistance.png'
                    alt='jetra travels'
                    style={{ height: '70px' }}
                  />
                </div>
                <NavLink to='/blogs/4' exact>
                  <p style={{ color: '#cc3300' }}>
                    SERVICE <br /> ASSISTANCE
                  </p>
                </NavLink>
              </div>
            </Col>
            <Col xl={2} md={6} className='col__con'>
              <div className='inner__con'>
                <div className='svg__con'>
                  <img
                    src='/icons/tours-travel.png'
                    alt='jetra travels'
                    style={{ height: '70px' }}
                  />
                </div>
                <NavLink to='/blogs/5' exact>
                  {' '}
                  <p style={{ color: '#cc3300' }}>
                    TOURS & <br />
                    TRAVEL
                  </p>
                </NavLink>
              </div>
            </Col>

            <Col xl={2} md={6} className='col__con'>
              <div className='inner__con'>
                <div className='svg__con'>
                  <img
                    src='/icons/stage.png'
                    alt='jetra travels'
                    style={{ height: '70px' }}
                  />
                </div>
                <NavLink to='/blogs/6' exact>
                  <p style={{ color: '#cc3300' }}>CONFERENCES</p>
                </NavLink>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default Services
