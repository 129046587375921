import React, { useEffect, useState } from 'react'
import PageBanner from '../../components/PageBanner'
import '../../sass/pagebanner.scss'
import { destination } from '../../data/destination'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'

const Destination = () => {
  return (
    <div>
      <PageBanner title='The Jetra Fleet' />

      <section className='des__home__sec pb-5 pt-4'>
        <Container>
          <Row>
            {destination.map((item, index) => (
              <LinkContainer key={index} to={`/destination/${item.id}`}>
                <Col md={4} className='des__card my-4'>
                  <img
                    className='w-100'
                    src={item.thumbnail}
                    alt='jetra logistics'
                  />
                  <div className='overlay'></div>
                  <div className='title'>
                    <h5>{item.name}</h5>
                  </div>
                </Col>
              </LinkContainer>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default Destination
