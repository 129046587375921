export const blog = [
  {
    id: 1,
    title: 'Taxi',
    createdAt: 'Airport taxi to any destination',
    thumbnail: '../services/taxi.jpg',
    category: 'taxi',
    description:
      'Our airport taxi service is designed to provide you with a reliable and convenient transportation option to and from the airport. Whether you are arriving at your destination or heading back home, our airport taxi service ensures a comfortable and hassle-free journey.',
    quote: 'Travel in style the Jetra style.',
    details: [
      'When you book an airport taxi with us, you can expect a prompt and professional service. Our fleet of well-maintained vehicles and experienced drivers are ready to meet your transportation needs. Upon your arrival at the airport, our driver will be waiting for you at the designated meeting point, holding a sign with your name for easy identification. They will assist you with your luggage and ensure a smooth and comfortable ride to your destination.Our airport taxi service is available 24/7, so you can rely on us for your transportation needs at any time of the day. We understand the importance of punctuality, especially for airport transfers, and we strive to get you to your destination in a timely manner. Our drivers are familiar with the best routes and traffic patterns, ensuring efficient and stress-free travel.',
    ],
  },
  {
    id: 2,
    title: 'AIRTPORT TRANSFERS',
    createdAt: 'February 18, 2023',
    thumbnail: '../services/airport-transfers.jpg',
    category: 'AIRTPORT TRANSFERS',
    description:
      'We understand the importance of making your airport transfer as seamless and stress-free as possible. That is why we offer reliable, comfortable, and affordable airport transfer services to our clients..',
    quote: 'get to us anytime.....',
    details: [
      'We understand the importance of making your airport transfer as seamless and stress-free as possible. That is why we offer reliable, comfortable, and affordable airport transfer services to our clients.',
    ],
  },
  {
    id: 3,
    title: 'Meet & Greet.',
    createdAt: 'Meet & Greet services',
    thumbnail: '../services/meetassist1.jpg',
    category: 'Meet & Greet',
    description:
      'Our Meet & Greet airport service is designed to provide a warm and personalized welcome to travelers, ensuring a smooth and stress-free transition through the airport. We understand that arriving in a new destination can be overwhelming, and our dedicated team is here to make your airport experience more comfortable and convenient.',
    quote:
      'There is a way out of every box, a solution to every puzzle it’s just a matter of finding it.',
    details: [
      'When you book our Meet & Greet service, one of our friendly and professional representatives will be waiting for you at the arrival gate, holding a personalized sign with your name or company logo. They will assist you with all aspects of the airport process, including baggage handling, immigration and customs procedures, and any necessary documentation or visa requirements. Our team will ensure that you are efficiently guided through each step, minimizing wait times and simplifying the arrival process.Whether you are a first-time traveler, have specific mobility needs, or simply prefer the convenience of having a dedicated assistant, our Meet & Greet service is tailored to cater to your individual requirements. Our representatives are knowledgeable about the airport layout, services, and local information, so they can provide you with any necessary guidance or answer any questions you may have.With our Meet & Greet airport service, you can relax and focus on your journey, knowing that our professional team is there to provide personalized assistance and ensure a seamless arrival experience. We pride ourselves on delivering exceptional customer service and creating a positive first impression for our valued clients.',
    ],
  },
  {
    id: 4,
    title: 'SERVICE ASSISTANCE',
    createdAt: 'airport service assistance',
    thumbnail: '../services/service-assistance.jpg',
    category: 'Service assistance',
    description:
      "At our dedicated airport service assistance, we understand that navigating through busy airports can be a daunting and overwhelming experience. That's why we offer comprehensive service assistance to ensure your journey begins and ends with utmost convenience and peace of mind. Our friendly and professional airport service team is ready to assist you from the moment you arrive until your departure. Whether you're a seasoned traveler or new to flying, we provide personalized guidance and support, making your airport experience seamless and stress-free..",
    quote:
      'There is a way out of every box, a solution to every puzzle it’s just a matter of finding it.',
    details: [
      "Our airport service assistance covers a wide range of services tailored to your specific needs. From meet and greet services upon arrival, where our representatives will be waiting for you at the gate or arrival hall, to baggage handling and transportation arrangements, we take care of all the details. We can assist you with check-in procedures, security clearance, and even fast-track you through immigration and customs to minimize waiting times. If you have a connecting flight, we'll ensure a smooth transition between terminals or assist you with rebooking if any unexpected changes occur. Our goal is to provide you with a personalized and attentive service that allows you to focus on enjoying your journey while we handle the logistics.Whether you require assistance with special requests, such as wheelchair accessibility or travel documentation, or simply desire the convenience and peace of mind that comes with having a dedicated airport service team by your side, our committed staff is here to ensure a hassle-free and enjoyable travel experience from start to finish.",
    ],
  },
  {
    id: 5,
    title: 'TOURS & TRAVEL',
    createdAt: 'December 22, 2022',
    thumbnail: '../destinations/tour-van.jpg',
    category: 'all tours',
    description:
      'Welcome to Jetra Logistics Tours, where we specialize in creating unforgettable travel experiences for our valued customers. With a passion for exploration and a commitment to exceptional service, we take pride in curating the perfect tour packages that cater to a variety of interests and preferences. Whether you dream of immersing yourself in the rich history of ancient civilizations, embarking on thrilling adventures in exotic destinations, or simply indulging in a luxurious getaway, our diverse range of tours promises to fulfill your wanderlust and create lifelong memories.',
    quote:
      'There is a way out of every box, a solution to every puzzle it’s just a matter of finding it.',
    details: [
      "At our tours company, we understand that every traveler is unique, and we strive to provide personalized experiences that exceed expectations. Our team of expert travel consultants works diligently to design meticulously crafted itineraries that encompass the most captivating destinations, handpicked accommodations, and engaging activities. We take into account your individual preferences, ensuring a seamless and tailored journey that reflects your interests, whether you're seeking cultural encounters, natural wonders, or culinary delights. With our attention to detail and commitment to customer satisfaction, we guarantee an exceptional travel experience that will leave you longing for more.",
    ],
  },

  {
    id: 6,
    title: 'CONFERENCE TRANSFERS',
    createdAt: 'Conferencing',
    thumbnail: '../services/airport-transfer.webp',
    category: 'CONFERENCE TRANSFERS',
    description:
      'Executive conference transfers provide a VIP transportation experience for business professionals attending conferences, meetings, or events. This premium service is designed to cater to the unique needs of executives, offering comfort, luxury, and reliability. Our specialty is on-time transportation throughout Kenya. We are also a leader in affordable transit to and from all major area airports including JKIA International Airport, Wilson Airport, and Moi International Airport. We use advanced, convenient dispatch technology to expeditiously reach our clients, and our easy-to-use personal and corporate accounts make payments and reservations a no-brainer.',
    quote:
      'There is a way out of every box, a solution to every puzzle it’s just a matter of finding it.',
    details: [
      "With our executive conference transfer service, we prioritize efficiency and attention to detail. We meticulously plan the logistics, taking into consideration traffic patterns, event schedules, and any specific requests you may have. Our dedicated team will work closely with you to ensure that all transportation needs are met, whether it's coordinating multiple pickups, arranging private transfers, or providing on-site support during the conference.Rest assured, when you choose our executive conference transfer service, you can focus on the core objectives of your event, knowing that your transportation needs are in capable hands. Our commitment to professionalism, reliability, and personalized service ensures that your executive conference transfers are executed flawlessly, contributing to the overall success of your business event.",
    ],
  },
  {
    id: 7,
    title: 'MEET & ASSIST',
    createdAt: 'MEET & ASSIST',
    thumbnail: '../services/meet-greet.jpg',
    category: 'MEET & ASSIST',
    description:
      "As a leading tours and travel company, we offer a remarkable Meet and Assist Airport Service. Our dedicated team of airport representatives ensures a seamless and personalized experience, guiding you through check-in, security, and immigration procedures. With our assistance, you can relax and enjoy a stress-free journey from start to finish.Count on us to provide exceptional customer service and unforgettable travel experiences. Our flexible and customizable Meet and Assist Airport Service caters to your unique needs, whether you're traveling with family, require special assistance, or seek VIP treatment. Trust us to be your reliable travel partner, delivering a smooth and hassle-free airport experience that enhances your overall trip.",
    quote:
      'Arrive with ease, depart with peace of mind - our Meet and Assist Airport Service ensures a seamless and stress-free travel experience.',
    details: [
      "We understand that each traveler has unique needs and preferences. That's why our Meet and Assist Airport Service is flexible and customizable to cater to your specific requirements. Whether you need assistance for a family trip, require wheelchair accessibility, or desire VIP treatment, our team will tailor our service to match your expectations.At our tours and travel company, we are committed to delivering exceptional customer service and unforgettable travel experiences. Our Meet and Assist Airport Service is just one example of how we go the extra mile to ensure your journey is seamless, comfortable, and stress-free. Trust us to be your reliable travel partner, providing you with unrivaled assistance and support every step of the way.",
    ],
  },
]
