export const tour = [
  {
    id: 1,
    name: 'MAASAI MARA',
    place: 'maasai mara park',
    category: ['adventure', 'tour'],
    price: 768,
    discount: null,
    children: 100,
    duration: {
      days: 3,
      night: 4,
    },
    month: {
      checkin: 'January 1, 2023',
      checkout: 'January 8,2023',
    },
    max: 15,
    description: [
      "Get to the maasai mara at the right time of the year you will be treated to great migration of millions of wildebeest this movement of the herbivores is immense, over two million animals migrate from the Serengeti National Park in Tanzania to the Maasai Mara National Reserve in Kenya in July through to October. They cross over to get greener pastures during which time the grass is green and at an average human height and by the time they are migrating back all that grass is all consumed and at an ankle height. The migration has to cross the famous Mara River in the Maasai Mara home to hundreds of crocodiles who will stop  noting to devour the tasty prey crossing over. This is one of the highlights as the animals try to cross over the Mara River' alive In the Maasai Mara they will be hunted, stalked, and run down by the larger carnivores. The Maasai Mara also has one of the largest densities of lion in the world and is no wonder this is the home of the BBC wildlife channels Big Cat Diary.",
    ],
    thumbnail: '../destinations/masai-mara-main.jpg',
    images: [
      '../destinations/masai-mara-main.jpg',
      '../destinations/giraffe-masai-mara.jpg',
      '../destinations/lion-masai-mara.jpg',
    ],
    Destination: 'Maasai Mara',
    departureandreturn: 'JKIA International Airport',
    departuretime: 'Please arrive by 9:15 AM for a departure at 9:30 AM',
    returntime: 'Approximately 10:30 PM',
    included: [
      'Free Best Tour Guided',

      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: [
      'Additional Services',
      'Insurance',
      'Transport',
      'Entry Fees',
      'Any Private Expenses',
    ],
  },
  {
    id: 2,
    name: 'AMBOSELI',
    place: 'AMBOSELI NATIONAL PARK',
    category: ['cruises', 'Nature', 'travel'],
    price: 589,
    discount: null,
    children: 100,
    duration: {
      days: 3,
      night: 4,
    },
    month: {
      checkin: 'February 1, 2023',
      checkout: 'February 8,2023',
    },
    max: 45,
    description: [
      "Explore Amboseli National Park on a 3-day safari from Nairobi that can be fully tailored to your preferences. Exclusive private safaris offer accommodations from a luxurious lodge to classic tents, or you can select a cost -effective shared option with a small group of travelers. Whatever you choose, enjoy daily game drives at the base of Mount Kilimanjaro, with the chance to spot vast elephant herds, brilliant birds, and top predators. Private option ensures one -on-one attention from safari guides Group safari is capped at six travelers for personalized service Included meals and accommodations take care of logistics Convenient pickup and drop -off at the Nairobi airport or hotels What's Included Hotel and airport pick-up and drop-off.",
    ],
    thumbnail: '../destinations/amboseli-main.jpg',
    images: [
      '../destinations/masai-calture-1.jpg',
      '../destinations/giraffe-masai-mara.jpg',
      '../destinations/lion-masai-mara.jpg',
    ],
    Destination: 'Amboseli',
    departureandreturn: 'JKIA International Airport',
    departuretime: 'Please arrive by 9:15 AM for a departure at 9:30 AM',
    returntime: 'Approximately 10:30 PM',
    included: [
      'Free Best Tour Guided',

      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: [
      'Additional Services',
      'Insurance',
      'Transport',
      'Entry Fees',
      'Any Private Expenses',
    ],
  },
  {
    id: 3,
    name: 'Nairobi National Park',
    place: 'Nairobi City,  National Park',
    category: ['adventure', 'city', 'cruises', 'travel'],
    price: 130,
    discount: null,
    children: 100,
    duration: {
      days: '',
      night: '',
    },
    month: {
      checkin: 'March 1, 2023',
      checkout: 'March 8,2023',
    },
    max: 24,
    description: [
      "Located just south of the city, Nairobi national park is Kenya's first game reserve and the only protected area in the world that sits so close to a nation's capital. Visitors to the vast wildlife park are likely to spot black rhinos, lions, giraffes, zebras as well as some 400 bird species.Our driver/guide will pick you up from your residence/hotel at 0600hrs after which you will proceed to the park in our spacious comfortable vehicle. Due to the early pickup we advise you request for early breakfast at the hotel or take away.We will drop you back at your designated point after 4hours of scenic/captivating breathtaking sight of our national park. While at the park you can take a break and unwind at the beautiful scenic Impala Observation Point and picnic site.Please note that elephants are not found in Nairobi national park. The tour is, also available in the afternoon from 1:.30p.m to 6.30p.m but morning is better as it is cooler and easier to track the animals).",
    ],
    thumbnail: '../destinations/nairobi-park.jpg',
    images: [
      '../destinations/crocodile.jpg',
      '../destinations/giraffe-masai-mara.jpg',
      '../destinations/buffalo-masai-mara.jpg',
    ],
    Destination: 'Nairobi',
    departureandreturn: 'JKIA International Airport',
    departuretime: 'Please arrive by 9:15 AM for a departure at 9:30 AM',
    returntime: 'Approximately 10:30 PM',
    included: [
      'Free Best Tour Guided',

      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: [
      'Additional Services',
      'Insurance',
      'Transport',
      'Entry Fees',
      'Any Private Expenses',
    ],
  },
  {
    id: 4,
    name: 'Bomas of Kenya',
    place: 'Bomas',
    category: ['adventure', 'culture', 'travel'],
    month: {
      checkin: 'April 1, 2023',
      checkout: 'April 8,2023',
    },
    price: 80,
    discount: '',
    children: 100,
    duration: {
      days: '',
      night: '',
    },
    max: 24,
    description: [
      'Bomas of Kenya is a tourist village and cultural center six miles outside of Nairobi. It features replicas of traditional homesteads, or bomas, from 23 of Kenya’s ethnic groups, as well as traditional dances performed in its large auditorium.Bomas of Kenya was founded by the Kenya Tourist Development Cooperation in 1971.',
    ],
    thumbnail: '../destinations/bomas-1.jpg',
    images: [
      '../destinations/bomas-2.jpg',
      '../destinations/bomas-3.jpg',
      '../destinations/bomas-4.jpg',
    ],
    Destination: 'Nairobi kenya',
    departureandreturn: 'Kath. Man International Airport',
    departuretime: 'Please arrive by 9:15 AM for a departure at 9:30 AM',
    returntime: 'Approximately 10:30 PM',
    included: [
      'Free Best Tour Guided',

      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: [
      'Additional Services',
      'Insurance',
      'Transport',
      'Entry Fees',
      'Any Private Expenses',
    ],
  },
  {
    id: 5,
    name: 'Karen Blixen ',
    place: 'karen',
    category: ['city', 'adventure', 'wedding'],
    price: 100,
    discount: null,
    children: 100,
    duration: {
      days: '',
      night: '',
    },
    month: {
      checkin: 'May 1, 2023',
      checkout: 'May 8,2023',
    },
    max: 24,
    description: [
      'Set in the serene suburb of Nairobi, the Karen Blixen Museum not only provides the most comprehensive insight into the person of Karen Blixen, but also offers a fantastic space for personal reflection in our nature trail, against a back drop of Karen’s beloved Ngong Hills.',
    ],
    thumbnail: '../destinations/brixen-main.jpg',
    images: [
      '../destinations/brixen-2.jpg',
      '../destinations/brixen-1.jpg',
      '../destinations/brixen-3.jpg',
    ],
    Destination: 'Nairobi Karen',
    departureandreturn: 'JKIA International Airport',
    departuretime: 'Please arrive by 9:15 AM for a departure at 9:30 AM',
    returntime: 'Approximately 10:30 PM',
    included: [
      'Free Best Tour Guided',

      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: [
      'Additional Services',
      'Insurance',
      'Transport',
      'Entry Fees',
      'Any Private Expenses',
    ],
  },
  {
    id: 6,
    name: 'Giraffe Center ',
    place: 'Girraffe center',
    category: ['city', 'adventure', 'giraffe'],
    price: 100,
    discount: null,
    children: 100,
    duration: {
      days: '',
      night: '',
    },
    month: {
      checkin: 'May 1, 2023',
      checkout: 'May 8,2023',
    },
    max: 24,
    description: [
      'The Nairobi Giraffe Center is a unique conservation facility located in Nairobi, Kenya. It was established in 1979 by the African Fund for Endangered Wildlife (AFEW) as an effort to save the endangered Rothschild giraffe species. The center is situated on a 60-acre plot within the Langata suburb, just outside Nairobi National Park.One of the main goals of the Nairobi Giraffe Center is to educate the public about giraffes and their conservation. Visitors to the center have the opportunity to observe and interact with the giraffes up close. The center offers a platform where visitors can feed the giraffes by hand and learn about their behavior, habitat, and conservation efforts.The center plays a crucial role in the breeding program for Rothschild giraffes, which are classified as endangered by the International Union for Conservation of Nature (IUCN). Through its breeding program, the center has successfully reintroduced several giraffes into various Kenyan national parks and private conservancies, contributing to the population growth and conservation of the species.Apart from its conservation efforts, the Nairobi Giraffe Center also serves as an educational and research institution. It conducts various research projects focused on giraffe behavior, genetics, and habitat conservation. The center also collaborates with local and international organizations to raise awareness about giraffes and promote their protection.Visiting the Nairobi Giraffe Center is not only an exciting and memorable experience but also a way to support giraffe conservation efforts. By providing a close encounter with these majestic creatures, the center creates a deeper connection between visitors and wildlife, fostering a sense of responsibility and care for the natural world.',
    ],
    thumbnail: '../destinations/girraffe-centre1.jpg',
    images: [
      '../destinations/girraffe-centre1.jpg',
      '../destinations/girraffe-centre2.jpg',
      '../destinations/girraffe-centre3.jpg',
      '../destinations/girraffe-centre4.jpg',
      '../destinations/girraffe-centre5.jpg',
    ],
    Destination: 'Nairobi Karen',
    departureandreturn: 'JKIA International Airport',
    departuretime: 'Please arrive by 9:15 AM for a departure at 9:30 AM',
    returntime: 'Approximately 10:30 PM',
    included: [
      'Free Best Tour Guided',

      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: [
      'Additional Services',
      'Insurance',
      'Transport',
      'Entry Fees',
      'Any Private Expenses',
    ],
  },
  {
    id: 7,
    name: 'Lake Nakuru',
    place: 'Lake Nakuru',
    category: ['sea', 'travel', 'express'],
    price: 250,
    discount: null,
    children: 100,
    duration: {
      days: '',
      night: '',
    },
    month: {
      checkin: 'June 1, 2023',
      checkout: 'June 8,2023',
    },
    max: 24,
    description: [
      'On the floor of the Great Rift Valley, surrounded by wooded and bushy grassland, lies the beautiful Lake Nakuru National Park. Visitors can enjoy the wide ecological diversity and varied habitats that range from Lake Nakuru itself to the surrounding escarpment and picturesque ridges. Lake Nakuru National Park is ideal for bird watching, hiking,picnic and game drives.',
    ],
    thumbnail: '../destinations/lake-nakuru.jpg',
    images: [
      '../destinations/lake-nakuru-1.jpg',
      '../destinations/lake-nakuru-2.jpg',
      '../destinations/lake-nakuru-3.jpg',
      '../destinations/lake-nakuru-4.jpg',
      '../destinations/lake-nakuru.jpg',
    ],
    Destination: 'Lake Nakuru',
    departureandreturn: 'JKIA International Airport',
    departuretime: 'Please arrive by 9:15 AM for a departure at 9:30 AM',
    returntime: 'Approximately 10:30 PM',
    included: [
      'Free Best Tour Guided',

      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: [
      'Additional Services',
      'Insurance',
      'Transport',
      'Entry Fees',
      'Any Private Expenses',
    ],
  },
  {
    id: 8,
    name: 'Nairobi City Tour 3 hours',
    place: 'Nairobi city',
    category: ['adventure', 'city', 'travel'],
    price: 100,
    discount: null,
    children: 100,
    duration: {
      days: '',
      night: '',
    },
    month: {
      checkin: 'July 1, 2023',
      checkout: 'July 8,2023',
    },
    max: 10,
    description: [
      'Embark on an exciting Nairobi adventure and immerse yourself in the vibrant energy of Kenya capital city. Explore the bustling streets filled with colorful markets, modern skyscrapers, and historical landmarks. Discover the rich cultural heritage of Nairobi through visits to museums, art galleries, and traditional craft centers. Indulge in delicious local cuisine, from street food to fine dining, showcasing the diverse flavors of Kenya. Immerse yourself in nature by visiting Nairobi National Park, where you can witness wildlife like giraffes, zebras, and lions against the backdrop of the city skyline. From vibrant city life to close encounters with wildlife, a Nairobi adventure promises a thrilling and unforgettable experience.',
    ],
    thumbnail: '../destinations/nairobi-city-3.jpg',
    images: [
      '../destinations/nairobi-city-2.jpg',
      '../destinations/nairobi-city-3.jpg',
      '../destinations/nairobi-city-1.jpg',
      '../destinations/nairobi-city-4.jpg',
    ],
    Destination: 'Nairobi city',
    departureandreturn: 'JKIA International Airport',
    departuretime: 'Please arrive by 9:15 AM for a departure at 9:30 AM',
    returntime: 'Approximately 10:30 PM',
    included: [
      'Free Best Tour Guided',

      'No Booking Charge',
      'Best Rate Gurantee',
    ],
    excludes: [
      'Additional Services',
      'Insurance',
      'Transport',
      'Entry Fees',
      'Any Private Expenses',
    ],
  },
]
